import React, { PureComponent } from 'react'

import { SettingsContext } from '../settings-context'

import Logo56 from './Logo56'
import CreditsImage from './CreditsImage'

const CreditsChild = ({ refCall, allowedToScroll, image }) => {
  return (
    <div ref={refCall} className={`credits-child ${allowedToScroll ? 'os' : 'oh'} psr c12 h100 curp`}>
      <div className='mxa pvx1 pvy1 credits-container psa cx c12'>
        <CreditsImage image={image} />

        <div className='c12 x xw xac xjb tac ttu fsA pvy4 fw500 lh1'>
          <div><p>Round <br /> One</p></div>
          <div><p>On <br /> Practices</p></div>
          <div><p>Round <br /> One</p></div>
        </div>

        <div className='pvx1 pvy3 bgc-g fsA tac b1 c-g brad mvb4 hyphens-off'>
          <p>
            On Practices is an online editorial platform for dialogue on building and maintaining creative practices. Each round is comprised of ten text-based interviews with notable practitioners in various spaces.
          </p>

        </div>

        <div className='pvb2 psr x xw c12 credits-list fw500 c-g bb1 mvb4'>
          <div className='c12 x xw xac credits-list-header pvb2'>
            <div className='line bc-b bt1' />
            <div className='special-thanks px1 ff-ogg'>special thanks to our participants</div>
            <div className='line bc-b bt1' />
          </div>

          <p className='c6'>WCROW</p>
          <p className='c6'>MISTER VICTORY</p>
          <p className='c6'>RONAN MCGEE</p>
          <p className='c6'>JIIN</p>
          <p className='c6'>JEFF SHIN</p>
          <p className='c6'>NICE & JUICY</p>
          <p className='c6'>KRISTIE MULLER</p>
          <p className='c6'>HARRISON</p>
          <p className='c6'>PLUS</p>
          <p className='c6'>KID. STUDIO</p>
        </div>

        <div className='c12 tac pvb4'>
          <h2 className='c12 round-title ff-ogg r2 tl11'>Round2</h2>
          <p className='c12 ttu fw500'>ETA Summer 2019</p>
        </div>

        <div className='credits-application tac mvb4'>
          <p className=''>Apply for round 2 by</p>
          <p className='bgc-b c-w  dib mxa credits-btn brad curd'><a href='https://onpractices.typeform.com/to/lLXho6' target='_blank' rel='noopener noreferrer'>clicking here</a></p>
        </div>

        <div className='credits-footer br50 b1 bc-w tac mxa mvb4'>
          <p>This project was conceived, designed and developed by</p>
          <div className='mxa'><Logo56 /></div>
        </div>

        <div className='c12 mvb4 tac fw500'>
          <p>Click anywhere to enter</p>
        </div>
      </div>
    </div>
  )
}

class CreditsParent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      scrolling: true,
      scrollingPos: 0,
      height: false,
      allowedToScroll: false
    }

    this.creditsContainer = false

    this.scrollSelf = this.scrollSelf.bind(this)
    this.stopScroll = this.stopScroll.bind(this)
    this.preventScroll = this.preventScroll.bind(this)

    this.handleResize = this.handleResize.bind(this)
    this.attachHandlers = this.attachHandlers.bind(this)

    this.childRef = React.createRef()
  }

  componentDidMount () {
    this.attachHandlers()
    this.handleResize()

    this.frame = window.requestAnimationFrame(this.scrollSelf)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
    window.cancelAnimationFrame(this.frame)
    this.frame = undefined
  }

  attachHandlers () {
    window.addEventListener('resize', this.handleResize)
    // window.addEventListener('scroll', this.preventScroll)
  }

  preventScroll (e) {
    e.preventDefault()
  }

  handleResize () {
    if (!this.childRef.current) {
      return false
    }
    this.creditsContainer = this.childRef.current.querySelector('.credits-container')

    if (this.creditsContainer) {
      const height = this.creditsContainer.offsetHeight - 500
      this.setState({ height })
    }
  }

  stopScroll () {
    this.frame = undefined
    this.setState({ allowedToScroll: true })
  }

  scrollSelf () {
    const { scrollingPos, height, userScrolling } = this.state
    const c = this.childRef.current

    if (c && !userScrolling) {
      var increment = window.innerWidth > 768 ? 4 : 2
      this.setState({ scrollingPos: this.state.scrollingPos + increment })
      c.scrollTop = parseInt(this.state.scrollingPos)
    }

    this.frame = window.requestAnimationFrame(this.scrollSelf)

    if (scrollingPos >= height) {
      window.cancelAnimationFrame(this.frame)
      this.stopScroll()
    }
  }

  render () {
    const { allowedToScroll } = this.state
    return (
      <aside onClick={this.props.closeCredits} style={{ height: window.innerHeight}} id='credits' className='psf t0 l0 c12 oh bgc-w'>
        <CreditsChild image={this.props.image} allowedToScroll={allowedToScroll} refCall={this.childRef} />
      </aside>
    )
  }
}

class Credits extends PureComponent {
  render () {
    return (
      <SettingsContext.Consumer>
        {context => {
          return <CreditsParent image={this.props.image} closeCredits={this.props.closeCredits} c={context} />
        }}
      </SettingsContext.Consumer>
    )
  }
}

export default Credits
