import React, { PureComponent } from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Credits from '../components/Credits'
import HomepagePost from '../components/HomepagePost'
import HomepageSlideshow from '../components/HomepageSlideshow'

class Homepage extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showCredits: undefined
      // showCredits: true
    }

    this.closeCredits = this.closeCredits.bind(this)
  }

  componentDidMount () {
    const showCredits = window.sessionStorage.getItem('SHOW_CREDITS') ? window.sessionStorage.getItem('SHOW_CREDITS') : true
    if (window.location.search === '?submitted=true') {
      window.scrollTo(0, 400)
    } else {
      this.setState({ showCredits: showCredits })
    }
  }

  closeCredits () {
    this.setState({ showCredits: false }, () => {
      window.sessionStorage.setItem('SHOW_CREDITS', false)
    })
  }

  render () {
    const { showCredits } = this.state
    const { data } = this.props
    const homepageSlideshow = data.allPages.edges[0].node.data
    const allPosts = data.allPosts.edges
    const shuffledPosts = _.shuffle(allPosts)

    const creditsImage = data.creditsImage
    return (
      <Layout bodyBlurred={showCredits}>
        <Helmet>
          <title>On Practices</title>
          <meta name='description' content='On Practices is an online editorial platform for dialogue on developing and maintaining a creative practice.' />
        </Helmet>

        {showCredits === true ? <Credits image={creditsImage.childImageSharp} closeCredits={this.closeCredits} /> : null}

        <HomepageSlideshow posts={homepageSlideshow.homepage_posts} />
        <div className='x xw c12 px1 content'>
          {shuffledPosts.map((post, i) => <HomepagePost key={post.node.uid} {...post} index={i} />)}
        </div>
      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
    creditsImage: file(relativePath: { eq: "promo.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allPages: allPrismicHomepage {
      edges {
        node {
          id
          data {
            homepage_posts {
              post_link {
                uid
              }
              homepage_image {
                url
                localFile{
                  childImageSharp {
                    fluid(quality: 95) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              homepage_subtitle {
                text
              }
            }
          }
        }
      }
    }
   allPosts: allPrismicPost {
     edges {
        node {
          uid
          id
          data {
            title {
              html
              text
            }
            field {
              html
              text
            }
            company {
              html
              text
            }
            cover_photo {
              alt
              copyright
              url
              localFile{
                childImageSharp {
                  fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
