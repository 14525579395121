import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
import { withPrefix } from 'gatsby'

class CreditsImage extends PureComponent {
  render () {
    console.log(this.props, ' CREDITS IMAGE PROPS')
    return (
      <div className='credits-image'>
        <img src={withPrefix('promo.png')} alt='Promo' />
        {/* <Img fluid={this.props.image.fluid} /> */}
      </div>
    )
  }
}

export default CreditsImage
