import React, { Component } from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

import Image from './Image'

const Row = ({
  homepage_image,
  homepage_subtitle,
  post_link,
  timer,
  index,
  selectedIndex
  }) => {
  return (
    <Link className={`${index === selectedIndex ? 'db' : 'dn'}`} to={`/posts/${post_link.uid}`}>
      <figure className='hp-slideshow-image tac'>
        {/* <img alt={homepage_image.url} className='mxa' src={homepage_image.url} /> */}
        <Image data={homepage_image.localFile.childImageSharp} />
      </figure>

      <div className='mt2 tac'>
        <TimerComponent time={timer} />{homepage_subtitle.text && <span>{homepage_subtitle.text}</span>}
      </div>
    </Link>
  )
}

const TimerComponent = ({ time }) =>
  <div className='timer psr b1 bcb dib vam mr1'>
    <span className='c psa'>{time}</span>
  </div>

class HomepageSlideshow extends Component {
  constructor () {
    super()
    this.state = {
      startTime: 5,
      timer: 5,
      index: 0,
      posts: [],
      maxPosts: false
    }

    this.runTimer = this.runTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.nextPost = this.nextPost.bind(this)
  }

  componentDidMount () {
    this.runTimer()
    this.setState({ posts: _.shuffle(this.props.posts) })
  }

  componentWillUnmount () {
    window.clearInterval(this.runTimer)
  }

  nextPost () {
    const { index } = this.state
    const { posts } = this.props

    if (index < posts.length - 1) {
      this.setState({ index: index + 1 })
    } else {
      this.setState({ index: 0 })
    }
  }

  runTimer () {
    this.timeInterval = setInterval(() => {
      const { startTime, timer } = this.state
      if (timer > 0) {
        this.setState({ timer: timer - 1 })
      } else {
        this.setState({ timer: startTime })
        this.nextPost()
      }
    }, 1000)
  }

  stopTimer () {
    clearInterval(this.timeInterval)
  }

  render () {
    const { timer, index, posts } = this.state
    return (
      <section id='hp-slideshow-parent' className='c12 py4 px2 bcb bb1'>
        <div id='hp-slideshow' className='mxa'>
          {posts.map((post, i) => <Row {...post} index={i} selectedIndex={index} timer={timer} key={`ss-${i}`} />)}
        </div>
      </section>
    )
  }
}

export default HomepageSlideshow
